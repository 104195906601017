html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

html {
  padding: 0;
  margin: 0;
}

body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}

img {
  border: none;
  display: block;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
}

*:focus {
  outline: none;
}

* {
  box-sizing: border-box;
}

li {
  list-style: none;
}

input,
textarea,
button,
select {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #f1f1f1;
}

::-webkit-scrollbar:vertical {
  width: 1vw;
}

::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 1vw;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border: 0px solid #f1f1f1;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
}

::placeholder,
::placeholder,
::placeholder {
  color: #a6abc1;
}

:-ms-input-placeholder,
:-ms-input-placeholder,
:-ms-input-placeholder {
  color: #a6abc1;
}

::-ms-input-placeholder,
::-ms-input-placeholder,
::-ms-input-placeholder {
  color: #a6abc1;
}

input[type='checkbox'] {
  width: 0.8vw;
  height: 0.8vw;
  min-width: 0.8vw;
  min-height: 0.8vw;
  margin: 0px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d2d5e0;
  box-sizing: border-box;
  border-radius: 0.1vw;
  margin-right: 0.5vw;
}

input[type='checkbox']:checked {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

.flexbox {
  display: flex;
  flex-flow: row wrap;
}

@font-face {
  font-family: 'poppins-extrabold';
  src: url('/assets/fonts/Poppins-ExtraBold.eot');
  src: url('/assets/fonts/Poppins-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('/assets/fonts/Poppins-ExtraBold.woff') format('woff'),
    url('/assets/fonts/Poppins-ExtraBold.ttf') format('truetype'),
    url('/assets/fonts/Poppins-ExtraBold.svg#Poppins-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'poppins-bold';
  src: url('/assets/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Bold.woff') format('woff'),
    url('/assets/fonts/Poppins-Bold.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Bold.svg#Poppins-Bold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-semibold';
  src: url('/assets/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-SemiBold.woff') format('woff'),
    url('/assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('/assets/fonts/Poppins-SemiBold.svg#Poppins-SemiBold') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-medium';
  src: url('/assets/fonts/Poppins-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Medium.woff') format('woff'),
    url('/assets/fonts/Poppins-Medium.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Medium.svg#Poppins-Medium') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-regular';
  src: url('/assets/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Regular.woff') format('woff'),
    url('/assets/fonts/Poppins-Regular.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Regular.svg#Poppins-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'poppins-light';
  src: url('/assets/fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/Poppins-Light.woff') format('woff'),
    url('/assets/fonts/Poppins-Light.ttf') format('truetype'),
    url('/assets/fonts/Poppins-Light.svg#Poppins-Light') format('svg');
  font-weight: normal;
  font-style: normal;
}

.row-headline {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2vw 0 1vw 0;
}

.row-headline h1 {
  font: 1.25vw/1vw poppins-bold;
  color: #212138;
}

.row-search {
  border-radius: 0.3vw;
  background: #f2f3f6;
  height: 2.604vw;
  width: 15vw;
  display: flex;
  align-items: center;
  position: relative;
}

.row-search input[type='text'] {
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
  border: none;
  height: 2.604vw;
  width: 12vw;
  background: none;
  margin-left: 10%;
}

.buttonsearch {
  width: 2.604vw;
  height: 2.604vw;
  background: url('/assets/images/icon-search.svg') no-repeat center / 0.8vw;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  color: transparent;
  cursor: pointer;
}

.buttonclose {
  width: 2.604vw;
  height: 2.604vw;
  background: url('/assets/images/icon-modal.svg') no-repeat center / 0.8vw;
  position: absolute;
  right: 0;
  top: 0;
  border: none;
  color: transparent;
  cursor: pointer;
}

.column-number-elements span {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  margin-right: 0.35vw;
}

.column-number-elements select {
  width: 3.5vw !important;
  padding-right: 1vw !important;
  background: url('/assets/images/icon-select.svg') no-repeat 75%/0.6vw !important;
  padding-left: 0.5vw !important;
}

.row-bar-filter {
  width: 100%;
  border-bottom: 0.1vw solid #d2d5e0;
  padding: 1vw 0;
}

.row-filter {
  width: 100%;
  display: flex;
}

.column-filter-date {
  display: flex;
  align-items: center;
}

.column-filter-date input[type='date'],
.column-filter-date input[type='text'] {
  border-radius: 0.3vw;
  display: flex;
  align-items: center;
  padding: 0.65vw 0.5vw;
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
  margin-right: 1vw;
  border: none;
  width: 12vw;
  background: #f2f3f6 url('/assets/images/icon-date-filter.svg') no-repeat 95% / 1vw;
  border: 1px solid #f2f3f6;
}
.column-filter-date input[type='date']::-webkit-calendar-picker-indicator {
  background: none;
}
.column-filter-date input[type='date']:focus,
.column-filter-date input[type='text']:focus {
  border: 1px solid #9093a0;
  color: #9093a0;
}
.column-filter-date span {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  margin-right: 1vw;
}

.column-filter-file {
  display: flex;
  align-items: center;
  margin-left: 1vw;
  border-left: 0.1vw solid #d2d5e0;
  padding-left: 1.25vw;
}
.column-filter-file span {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  margin-right: 1vw;
}

.switch-file {
  position: relative;
  display: inline-block;
  width: 2.85vw;
  height: 1.35vw;
}
.switch-file input {
  opacity: 0;
  width: 0;
  height: 0;
}
.switch-file div {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f2f3f6;
  transition: 0.4s;
  border-radius: 1vw;
}
.switch-file div:before {
  position: absolute;
  content: '';
  height: 1vw;
  width: 1vw;
  left: 0.3vw;
  top: 0.15vw;
  background: #bec3d6;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + div {
  background: #2196f3;
}
input:checked + div:before {
  transform: translateX(1.35vw);
  background: #fff;
}

.row-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1vw solid #d2d5e0;
  padding-bottom: 0.35vw;
}
.column-menu-bar {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.column-menu-bar select {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  height: 2.3vw;
  width: 16vw;
  padding-left: 1em;
  background: url('/assets/images/icon-select.svg') no-repeat 95% / 0.6vw;
  border: 0.1vw solid #d2d5e0;
  border-radius: 0.3vw;
  margin-right: 1vw;
}

.column-menu-bar a {
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
  margin-left: 1.25vw;
}

.column-menu-bar a.active {
  color: #212138 !important;
}

.column-menu-bar a:hover {
  color: #212138;
}

.column-button-bar {
  display: flex;
  align-items: center;
}

.button-filter {
  display: flex;
  border: none;
  background: none;
  cursor: pointer;
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
}

.button-filter i {
  width: 0.8vw;
  height: 1vw;
  background: url('/assets/images/icon-filter.svg') no-repeat center right / 100%;
  margin-left: 0.3vw;
}

.button-filter.active {
  color: #0091ff !important;
}

.button-filter.active i {
  background: url('/assets/images/icon-filter-active.svg') no-repeat center right / 100% !important;
}

.button-grid {
  width: 0.8vw;
  height: 0.8vw;
  background: url('/assets/images/icon-grid.svg') no-repeat center right / 100%;
  margin-right: 1vw;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.button-grid:hover {
  background: url('/assets/images/icon-grid-active.svg') no-repeat center right / 100% !important;
}

.button-grid.active {
  background: url('/assets/images/icon-grid-active.svg') no-repeat center right / 100% !important;
}

.button-list {
  width: 0.8vw;
  height: 0.8vw;
  background: url('/assets/images/icon-list.svg') no-repeat center right / 100%;
  margin-right: 1vw;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.button-list:hover {
  background: url('/assets/images/icon-list-active.svg') no-repeat center right / 100% !important;
}

.button-list.active {
  background: url('/assets/images/icon-list-active.svg') no-repeat center right / 100% !important;
}

#component-gallery-grid {
  width: 100%;
}

.row-gallery-grid {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.column-grid {
  width: 18.4%;
  margin-right: 2%;
  margin-top: 2%;
  position: relative;
}

.column-grid:nth-child(5n) {
  margin-right: 0;
}

.row-content-grid {
  width: 100%;
}

.row-data-grid {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.729vw;
  margin-bottom: 0.3vw;
}

.namecard {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.namecard label {
  font: 0.781vw/1vw poppins-regular;
  color: #212138;
}

.actioncard {
  position: relative;
  display: flex;
  flex-flow: row wrap;
}

.row-content-grid h2 {
  font: 0.677vw/1vw poppins-regular;
  color: #a6abc1;
}

.namecard input[type='checkbox'] {
  width: 0.7vw;
  height: 0.7vw;
  min-width: 0.7vw;
  min-height: 0.7vw;
  border: 2px solid #212138;
}

.box-dropdown-grid {
  background: #fff;
  position: absolute;
  top: 1.5vw;
  left: -8vw;
  width: 9.5vw;
  z-index: 9;
  border-radius: 0.3vw;
  box-shadow: 0px 3px 10px #21213833;
  padding: 1.35vw 1.5vw;
  display: none;
}

.box-dropdown-grid:before {
  content: '';
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: -1.1vw;
  right: 0.5vw;
}

.box-dropdown-grid button {
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  background: none;
  margin-bottom: 1vw;
}

.box-dropdown-grid button i {
  width: 0.8vw;
  height: 0.8vw;
  margin-right: 0.5vw;
}

.box-dropdown-grid button:last-child {
  margin: 0;
}

.box-select span {
  font: 0.729vw/1vw poppins-bold;
  color: #212138;
  margin-right: 1.35vw;
}

.box-select button {
  cursor: pointer;
  border: 2px solid transparent;
  border-radius: 0.3vw;
  width: 2.292vw;
  height: 2.188vw;
  margin-right: 0.521vw;
  border: 0.15vw solid transparent;
}

.box-select button:hover {
  border: 0.15vw solid #0091ff;
}

.row-button-addmodel {
  width: 100%;
  padding: 4vw 0;
}

.button-addmodel {
  border: none;
  cursor: pointer;
  border-radius: 0.3vw;
  background: #0091ff;
  color: #fff;
  font: 0.729vw/1vw poppins-bold;
  height: 2.8vw;
  width: 9.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.button-addmodel:hover {
  background: #0085e9;
}

.addmodel {
  color: #0091ff !important;
}

.addmodel:hover {
  text-decoration: underline;
  color: #0091ff;
}

.row-table {
  width: 100%;
  margin-top: 1.5vw;
}

.row-table ul {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
}

.row-table ul li {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  border-bottom: 0.1vw solid #d2d5e0;
  padding: 0.9vw 0;
}

.row-table ul li:first-child div {
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
}

.row-table ul li .td:nth-child(1) {
  width: 30%;
}

.row-table ul li .td:nth-child(2) {
  width: 15%;
}

.row-table ul li .td:nth-child(3) {
  width: 20%;
}

.row-table ul li .td:nth-child(4) {
  width: 15%;
}

.row-table ul li .td:nth-child(5) {
  width: 15%;
}

.button-ordername {
  display: flex;
  color: #a6abc1;
  border: none;
  background: none;
  font: 0.729vw/1vw poppins-regular;
  cursor: pointer;
}

.button-ordername i {
  width: 1vw;
  height: 1vw;
  background: url('/assets/images/icon-arrow-up.svg') no-repeat center right / contain;
  margin-left: 0.2vw;
}

.row-table .td span {
  font: 0.9vw/1vw poppins-regular;
  color: #212138;
}

.row-table .nametable {
  display: flex;
  align-items: center;
}

.image-table {
  width: 5.5vw;
  height: 3.5vw;
  margin-right: 1vw;
}

.image-table img {
  width: 5.5vw;
  height: 3.5vw;
}

.buttontable {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.box-button-action {
  position: relative;
  display: flex;
  align-items: center;
}

.button-option {
  cursor: pointer;
  border: 2px solid transparent;
  margin-right: 0.5vw;
  border-radius: 0.3vw;
  width: 2.2vw;
  height: 2.3vw;
  background: #e5f4ff url('/assets/images/icon-archive-selected.svg') no-repeat center / 0.85vw;
  position: relative;
}

.box-button-action > button:hover,
.button-option:hover {
  border: 0.15vw solid #0091ff;
}

.button-edit-table {
  border: none;
  cursor: pointer;
  border-radius: 0.3vw;
  background: #212138;
  color: #fff;
  font: 0.729vw/1vw poppins-bold;
  height: 2.3vw;
  width: 6vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropdown-button-option {
  background: #fff;
  position: absolute;
  top: 2.8vw;
  left: -7.5vw;
  width: 9.5vw;
  z-index: 9;
  border-radius: 0.3vw;
  box-shadow: 0px 3px 10px #21213833;
  padding: 1.35vw 1.5vw;
  display: none;
}

.dropdown-button-option:before {
  content: '';
  width: 0;
  height: 0;
  border-right: 10px solid transparent;
  border-top: 10px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 10px solid #ffffff;
  position: absolute;
  top: -1.1vw;
  right: 0.5vw;
}

.dropdown-button-option button {
  width: 100%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  font: 0.77vw/1vw poppins-regular;
  color: #212138;
  background: none;
  margin-bottom: 1vw;
}

.dropdown-button-option button i {
  width: 0.8vw;
  height: 0.8vw;
  margin-right: 0.5vw;
}

.dropdown-button-option button:last-child {
  margin: 0;
}

.row-top-edit-gallery h2 {
  font: 1vw/1vw poppins-bold;
  color: #212138;
}

.row-field label {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  display: block;
  padding-bottom: 0.5vw;
}

.row-field select {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  height: 2.604vw;
  width: 100%;
  border: none;
  border-radius: 0.3vw;
  padding: 0 2vw 0 1vw;
  background: url('/assets/images/icon-select.svg') no-repeat 95% / 0.6vw;
  border: 0.1vw solid #d2d5e0;
}

.row-item ul {
  width: auto;
  display: flex;
}

.row-item ul li {
  background: #f8f8f8;
  border: 0.1vw solid #d2d5e0;
  padding: 0.5vw 0.729vw;
  border-radius: 0.3vw;
  display: flex;
  align-items: center;
  margin-right: 0.65vw;
}

.row-item ul li span {
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
}

.box-drag-drop h2 {
  font: 1vw/1vw poppins-bold;
  color: #a6abc1;
  background: url('/assets/images/icon-upload.svg') no-repeat center top / 2vw;
  padding-top: 3.75vw;
}

.box-drag-drop span {
  font: 0.729vw/1vw poppins-regular;
  color: #a6abc1;
  padding: 1vw 0;
  display: block;
}

.box-file button {
  border: none;
  cursor: pointer;
  border-radius: 0.3vw;
  background: #0091ff;
  color: #fff;
  font: 0.729vw/1vw poppins-bold;
  height: 2.604vw;
  width: 9vw;
  margin: 0 auto;
}

#module-upload-status div:last-child {
  border: none;
}

.row-upload-title h2 {
  font: 0.729vw/1vw poppins-regular;
  color: #fff;
  margin-left: 0.25vw;
}

.bar-progress div {
  background: #a6abc1;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: all 0.3s ease-in-out;
}

.row-message-error span {
  font: 0.9vw/1vw poppins-regular;
  color: #212138;
  margin-left: 0.3vw;
}

.button-upload {
  border: none;
  cursor: pointer;
  border-radius: 0.3vw;
  background: #0091ff;
  color: #fff;
  font: 0.729vw/1vw poppins-bold;
  height: 2.604vw;
  width: 8vw;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 1vw;
}

.row-top-edit-gallery {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2vw;
}

.close-edit-gallery {
  width: 0.8vw;
  height: 0.8vw;
  background: url('/assets/images/icon-modal.svg') no-repeat center right / 100%;
  border: none;
  cursor: pointer;
}

.row-center-edit-gallery {
  width: 100%;
  margin-top: 2.5vw;
  display: flex;
  justify-content: space-between;
}

.column-left-edit-gallery {
  width: 48%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: flex-start;
}

.row-field {
  width: 49%;
  margin-bottom: 1.5vw;
}

.row-field input[type='text'] {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  height: 2.604vw;
  width: 100%;
  border-radius: 0.3vw;
  padding: 0 1vw;
  border: 0.1vw solid #d2d5e0;
}
.row-field textarea {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
  min-height: 2.604vw;
  width: 100%;
  border-radius: 0.3vw;
  padding: 1vw;
  border: 0.1vw solid #d2d5e0;
}

.row-field input[type='text']:focus,
.row-field textarea:focus,
.row-field select:focus {
  border: 1px solid #0091ff;
}

.full-width {
  width: 100% !important;
}

.row-item {
  width: 100%;
  display: flex;
}

.delete-item {
  width: 0.6vw;
  height: 0.6vw;
  background: url('/assets/images/icon-modal.svg') no-repeat center right / 100%;
  border: none;
  cursor: pointer;
  border: none;
  margin-left: 1.5vw;
}

.button-add-item {
  background: #f8f8f8 url('/assets/images/icon-add.svg') no-repeat center / 0.729vw;
  border: 0.1vw solid #d2d5e0;
  border-radius: 0.3vw;
  width: 2.2vw;
  height: 2.1vw;
  cursor: pointer;
}

.button-add-item:hover {
  border: 0.1vw solid #0091ff;
  background: #0091ff url('/assets/images/icon-add-active.svg') no-repeat center / 0.729vw;
}

.row-qr {
  position: relative;
}

.row-qr input[type='text'] {
  position: relative;
  width: 88%;
  border: 0.1vw solid #d2d5e0;
}

.button-qr {
  background: #f8f8f8 url('/assets/images/icon-qr.svg') no-repeat center / 0.95vw;
  border: 0.1vw solid #d2d5e0;
  width: 2.604vw;
  height: 2.604vw;
  cursor: pointer;
  position: absolute;
  right: 2.604vw;
}

.button-copy {
  background: #f8f8f8 url('/assets/images/icon-copy.svg') no-repeat center / 0.9vw;
  border: 0.1vw solid #d2d5e0;
  width: 2.604vw;
  height: 2.604vw;
  cursor: pointer;
  position: absolute;
  right: 0;
  border-left: 0;
  border-top-right-radius: 0.3vw;
  border-bottom-right-radius: 0.3vw;
}

.column-right-edit-gallery {
  width: 48%;
}

.row-data-archive {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5vw;
}

.namearchive {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
}

.weightarchive {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
}

.titlethumbnail {
  font: 0.729vw/1vw poppins-regular;
  color: #212138;
}

.row-media-thumbnail {
  width: 100%;
  margin-top: 0.85vw;
  display: flex;
  flex-flow: row wrap;
}

.row-bottom-edit-gallery {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 1.3vw 7vw;
  background: #fff;
}

.button-save-edit-gallery {
  font: 0.729vw/1vw poppins-bold;
  color: #fff;
  width: 9vw;
  height: 2.604vw;
  border: none;
  border-radius: 0.3vw;
  background: #0091ff;
  cursor: pointer;
  margin: 0 0.3vw;
}

.button-cancel-edit-gallery {
  font: 0.729vw/1vw poppins-bold;
  color: #212138;
  width: 9vw;
  height: 2.604vw;
  border: none;
  border-radius: 0.3vw;
  background: #e9eaf0;
  cursor: pointer;
  margin: 0 0.3vw;
}

.button-save-edit-gallery:hover {
  background: #0085e9;
}

.button-cancel-edit-gallery:hover {
  background: #d7d7db;
}

.row-pagination {
  width: 100%;
  padding: 3vw 0 1vw 0;
}

.row-pagination div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.row-pagination a {
  font: 0.85vw/1vw poppins-regular;
  background: #e5f4ff;
  margin: 0 0.3vw;
  width: 1.6vw;
  height: 1.6vw;
  text-align: center;
  border-radius: 50%;
  color: #212138;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e5f4ff;
  text-decoration: none;
}

.row-pagination a.active-pagination {
  background: #0091ff !important;
  color: #fff !important;
  border: 1px solid #0091ff !important;
}

.row-pagination a:hover {
  border: 1px solid #0091ff !important;
}

.row-pagination button.button-prev-pagination {
  width: 1.2vw;
  height: 1.2vw;
  background: url('/assets/images/icon-prev-pagination.svg') no-repeat center / 100%;
  margin-right: 0.5vw;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.row-pagination button.button-next-pagination {
  width: 1.2vw;
  height: 1.2vw;
  background: url('/assets/images/icon-next-pagination.svg') no-repeat center / 100%;
  margin-left: 0.5vw;
  border: none;
  background-color: inherit;
  cursor: pointer;
}

.row-pagination button.disable-button {
  cursor: inherit;
  opacity: 0.2;
}

#row-loading {
  width: 100%;
  position: relative;
  z-index: 999;
}

#row-loading div {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-flow: column;
  align-items: center;
  height: 70vh;
}

#row-loading div span {
  font: 0.829vw/1vw poppins-regular;
  color: #a6abc1;
  text-align: center;
}

#row-loading div img {
  margin: 0 auto;
  width: 10vw;
}

@media screen and (max-width: 760px) {
  #row-loading div img {
    margin: 0 auto;
    width: 7em;
  }

  #row-loading div span {
    font: 0.729em/1em poppins-regular;
  }

  .column-number-elements {
    display: flex;
    align-items: center;
  }

  .column-number-elements span {
    font: 0.8em/1em poppins-regular;
    margin-right: 0.5em;
  }

  .column-number-elements select {
    width: 3.75em !important;
    background: none !important;
    padding-left: 0.5em !important;
    display: block !important;
    font: 0.85em/1em poppins-regular !important;
    height: 2.3em !important;
    border: 1px solid #d2d5e0 !important;
    border-radius: 5px !important;
  }

  .row-bar-filter {
    padding: 1em 5%;
    margin: 0;
  }

  .row-filter {
    flex-flow: column wrap;
  }

  .column-filter-date {
    flex-flow: row wrap;
    justify-content: space-between;
  }

  .column-filter-date span {
    font: 0.8em/1em poppins-regular;
    margin-right: 0;
    width: 100%;
    margin-bottom: 5px;
  }

  .column-filter-date input[type='date'],
  .column-filter-date input[type='text'] {
    border-radius: 0.3em;
    padding: 0.85em 0.35em;
    font: 0.75em/1em poppins-regular;
    margin-right: 0;
    width: 48%;
    background: #f2f3f6 url('/assets/images/icon-date-filter.svg') no-repeat 90% / 1.3em;
    border: 1px solid #f2f3f6;
  }

  .column-filter-file {
    margin-left: 0;
    border-left: 0vw solid #d2d5e0;
    padding-left: 0;
    margin-top: 1em;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  .column-filter-file span {
    font: 0.8em/1em poppins-regular;
    margin-right: 0;
    width: 100%;
    margin-bottom: 5px;
  }

  .switch-file {
    width: 3.5em;
    height: 1.4em;
  }
  .switch-file div {
    border-radius: 3em;
  }
  .switch-file div:before {
    content: '';
    height: 1em;
    width: 1em;
    left: 0.3em;
    top: 0.2em;
  }
  input:checked + div:before {
    transform: translateX(2em);
  }

  .row-headline {
    padding: 1.1em 5%;
    margin: 0;
  }

  .row-headline h1 {
    font: 1.4em/1em poppins-bold;
  }

  .row-search {
    display: none;
  }

  .row-bar {
    padding: 1.1em 5%;
    margin: 0;
  }

  .column-menu-bar select {
    display: none;
  }

  .column-menu-bar a {
    font: 0.9em/1em poppins-regular;
    margin-right: 1em;
    display: none;
  }

  .alloption {
    display: block !important;
    background: url('/assets/images/button-filter-mobile.svg') no-repeat left center / 0.8em;
    padding-left: 1.2em;
  }

  .button-grid {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
  }

  .button-list {
    width: 1em;
    height: 1em;
  }

  .button-filter {
    font: 0.75em/1em poppins-regular;
  }
  .button-filter i {
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
  }
  .row-bar {
    padding: 1.1em 5%;
    margin: 0;
  }

  #component-gallery-grid {
    padding: 1.1em 5%;
  }

  .column-grid {
    width: 100%;
    margin-right: 0;
    margin-top: 1em;
    position: relative;
  }

  .row-data-grid {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .namecard input[type='checkbox'] {
    width: 0.9em;
    height: 0.9em;
    min-width: 0.9em;
    min-height: 0.9em;
    border: 2px solid #212138;
  }

  .namecard label {
    font: 0.85em/1em poppins-regular;
  }

  .row-content-grid h2 {
    font: 0.75em/1em poppins-regular;
  }

  .button-addmodel {
    font: 0.8em/1em poppins-bold;
    height: auto;
    width: auto;
    padding: 1em 2em;
    border-radius: 3px;
    margin-bottom: 4em;
  }

  .box-select button {
    border-radius: 3px;
    width: 3.2em;
    height: 3.2em;
    margin-right: 0.5em;
    border: 1px solid transparent;
  }

  .box-select span {
    display: none;
  }

  .box-file button {
    border-radius: 5px;
    font: 0.85em/1em poppins-bold;
    height: auto;
    width: auto;
    padding: 1.1em 3em;
  }

  .box-capture-photo button {
    border-radius: 5px;
    font: 0.85em/1em poppins-bold;
    padding: 1.1em 3em;
    background: #0091ff;
    color: #fff;
    border: none;
  }

  .row-table ul li:first-child {
    display: none;
  }

  .row-table ul li {
    border: none;
    margin-bottom: 1em;
    background: #fff;
    position: relative;
    border-radius: 0.5em;
    padding-bottom: 6em;
  }

  .row-table ul li .td:nth-child(1) {
    width: 100%;
  }

  .image-table {
    width: 100%;
    height: auto;
    margin-right: 0;
  }

  .image-table img {
    width: 100%;
    height: auto;
  }

  .row-table .td span {
    font: 0.85em/1em poppins-regular;
    width: 100%;
    padding: 1em 0;
  }

  .row-table .nametable {
    flex-flow: row wrap;
  }

  .row-table ul li .td:nth-child(3),
  .row-table ul li .td:nth-child(4) {
    display: none;
  }

  .button-option {
    border: 1px solid transparent;
    margin-right: 7px;
    border-radius: 5px;
    width: 2.8em;
    height: 2.8em;
    background: #e5f4ff url('/assets/images/icon-archive-selected.svg') no-repeat center / 0.9em;
  }

  .button-edit-table {
    position: absolute;
    bottom: 2em;
    height: auto;
    width: 100%;
    border-radius: 5px;
    padding: 1.2em 0;
    font: 0.85em/1em poppins-bold;
    margin: 0 auto;
    left: 0;
    right: 0;
  }

  .row-top-edit-gallery h2 {
    font: 1em/1em poppins-bold;
  }

  .row-field label {
    font: 0.8em/1em poppins-regular;
    padding-bottom: 5px;
  }

  .row-field select {
    font: 0.8em/1em poppins-regular;
    height: 2.8em;
    border-radius: 3px;
    padding: 0 1em;
    border: 1px solid #d2d5e0;
    background: url('/assets/images/icon-select.svg') no-repeat 95% / 1em;
  }

  .row-item ul li {
    border: 1px solid #d2d5e0;
    padding: 0.5em 1em;
    border-radius: 3px;
    margin-right: 0.5em;
  }

  .row-item ul li span {
    font: 0.75em/1em poppins-regular;
  }

  .row-qr input {
    width: 70% !important;
  }

  .box-dropdown-grid {
    top: 3em;
    left: -5.5em;
    width: 8em;
    border-radius: 5px;
    box-shadow: 0 1em 1em #21213829;
    padding: 1.5em 1em;
  }

  .box-dropdown-grid button {
    font: 0.75em/1em poppins-regular;
    margin-bottom: 0.75em;
  }

  .box-dropdown-grid button i {
    width: 1em;
    height: 1em;
    margin-right: 5px;
  }

  .row-content-loading-upload img {
    width: 5em;
    height: 5em;
    margin: 0 auto;
  }

  .row-content-loading-upload div {
    padding: 1.5em 0;
  }

  .row-content-loading-upload div span {
    font: 0.8em/1.4em poppins-regular;
    color: #a6abc1;
  }

  .row-content-loading-upload div p {
    font: 0.8em/1.4em poppins-regular;
    color: #a6abc1;
  }

  .row-content-loading-upload div button {
    border-radius: 5px;
    font: 0.85em/1em poppins-bold;
    padding: 1.1em 4em;
    background: #e9eaf0;
    color: #212138;
    border: none;
    margin-top: 1em;
  }

  .button-upload {
    border-radius: 0.3em;
    font: 0.85em/1em poppins-bold;
    height: auto;
    width: auto;
    padding: 1.2em 3.5em;
  }

  .row-top-edit-gallery {
    padding-bottom: 1em;
  }

  .close-edit-gallery {
    width: 1em;
    height: 1em;
  }

  .row-center-edit-gallery {
    width: 100%;
    margin-top: 1em;
    flex-flow: column wrap;
  }

  .column-left-edit-gallery {
    width: 100%;
  }

  .column-right-edit-gallery {
    width: 100%;
  }

  .row-field {
    width: 100% !important;
    margin-bottom: 1em;
  }

  .row-field input[type='text'] {
    font: 0.8em/1em poppins-regular;
    height: 2.8em;
    border-radius: 3px;
    padding: 0 1em;
    border: 1px solid #d2d5e0;
  }

  .row-field textarea {
    font: 0.8em/1em poppins-regular;
    min-height: 2.8em;
    border-radius: 3px;
    padding: 1em;
    border: 1px solid #d2d5e0;
  }

  .delete-item {
    width: 0.7em;
    height: 0.7em;
    margin-left: 0.5em;
  }

  .button-add-item {
    background: #f8f8f8 url('/assets/images/icon-add.svg') no-repeat center / 35%;
    border: 1px solid #d2d5e0;
    border-radius: 3px;
    width: 2.2em;
    height: 2.2em;
  }

  .button-copy {
    background: #f8f8f8 url('/assets/images/icon-copy.svg') no-repeat center / 35%;
    border: 1px solid #d2d5e0;
    width: 2.7em;
    height: 2.7em;
    border-radius: 3px;
  }

  .button-qr {
    background: #f8f8f8 url('/assets/images/icon-qr.svg') no-repeat center / 35%;
    border: 1px solid #d2d5e0;
    width: 2.7em;
    height: 2.7em;
    border-radius: 3px;
    right: 3.5em;
  }

  .namearchive {
    font: 0.85em/1em poppins-regular;
    width: 70%;
  }

  .weightarchive {
    font: 0.7em/1em poppins-regular;
  }

  .titlethumbnail {
    font: 0.85em/1em poppins-regular;
    padding: 1em 0;
  }

  .row-data-archive {
    padding-top: 2em;
    padding-bottom: 1em;
  }

  .row-edit-gallery {
    width: 100%;
    padding: 3em 2em 10em 2em;
  }

  .row-bottom-edit-gallery {
    display: flex;
    justify-content: center;
    left: 0;
    bottom: 0em;
    padding: 1em 2em 2em 2em;
  }

  .button-save-edit-gallery {
    font: 0.75em/1em poppins-bold;
    width: auto;
    height: auto;
    border-radius: 5px;
    margin: 0 3px;
    padding: 0 2em;
  }

  .button-cancel-edit-gallery {
    font: 0.75em/1em poppins-bold;
    width: auto;
    height: auto;
    border-radius: 5px;
    margin: 0 3px;
    padding: 0 2em;
  }

  .row-pagination {
    width: 100%;
    padding: 3em 0 1em 0;
  }

  .row-pagination a {
    font: 0.75em/1em poppins-regular;
    margin: 0 3px;
    width: 1.8em;
    height: 1.8em;
  }

  .row-pagination button.button-prev-pagination,
  .row-pagination button.button-next-pagination {
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
}
